import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "card"
}
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = {
  class: "card card-bordered mb-5",
  ref: "invoice_information"
}
const _hoisted_5 = { class: "card-header ribbon mb-5" }
const _hoisted_6 = { class: "card-title mb-5 mt-5" }
const _hoisted_7 = { class: "card-subtitle text-muted" }
const _hoisted_8 = { class: "align-self-center text-gray-700" }
const _hoisted_9 = { class: "card-footer" }
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_information_modal = _resolveComponent("vc-information-modal")!
  const _component_vc_health_corporate_top = _resolveComponent("vc-health-corporate-top")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_vc_sub_group_amend_table = _resolveComponent("vc-sub-group-amend-table")!
  const _component_vc_group_invoice_information = _resolveComponent("vc-group-invoice-information")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_vc_button_navigator = _resolveComponent("vc-button-navigator")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_vc_invoice_modal = _resolveComponent("vc-invoice-modal")!
  const _component_vc_group_invoice_details = _resolveComponent("vc-group-invoice-details")!
  const _component_vc_group_invoice_log = _resolveComponent("vc-group-invoice-log")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[6] || (_cache[6] = [
          _createElementVNode("div", { class: "card" }, [
            _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.health)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("h3", { class: "card-title" }, "Category Addition")
          ], -1)),
          _createVNode(_component_vc_information_modal),
          _createVNode(_component_el_form, {
            ref: "ruleFormRef",
            model: _ctx.invoice,
            "status-icon": "",
            rules: _ctx.rules,
            id: "healthForm",
            "scroll-to-error": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_vc_health_corporate_top, {
                  ref: "top",
                  onReValidate: _ctx.revalidate,
                  onTab: _ctx.checkform,
                  isDisbaled: ""
                }, null, 8, ["onReValidate", "onTab"]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h6", _hoisted_7, [
                        _cache[8] || (_cache[8] = _createTextVNode(" Group Details ")),
                        _createElementVNode("a", {
                          class: "",
                          title: "Log",
                          href: "javascript:void(0)",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.invoiceLogModal && _ctx.invoiceLogModal(...args)))
                        }, _cache[7] || (_cache[7] = [
                          _createElementVNode("i", { class: "icon-svg icon-view-receipt" }, null, -1)
                        ]))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_el_button, {
                        type: "button",
                        class: "btn btn-sm btn-info w-10 h-auto me-10",
                        onClick: _ctx.addNewCategory
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" + Category ")
                        ])),
                        _: 1
                      }, 8, ["onClick"]),
                      _createElementVNode("a", {
                        href: "#",
                        class: "fw-bolder fs-6 a-link",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.invoiceDetailsModal && _ctx.invoiceDetailsModal(...args)), ["prevent"]))
                      }, "Main Invoice Amount: " + _toDisplayString(_ctx.common.formatCurrency(_ctx.invoiceDetails ? _ctx.invoiceDetails.invoice_amount : 0)) + " " + _toDisplayString(_ctx.currency), 1),
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "ms-3" }, null, -1))
                    ])
                  ]),
                  (_ctx.health.insured_type_id == 4)
                    ? (_openBlock(), _createBlock(_component_vc_sub_group_amend_table, {
                        key: 0,
                        ref: "groupInfoRef",
                        onReValidate: _ctx.revalidate,
                        "is-sub": "",
                        onValidate: _ctx.validateGroupDetails
                      }, null, 8, ["onReValidate", "onValidate"]))
                    : _createCommentVNode("", true)
                ], 512),
                _createVNode(_component_vc_group_invoice_information, {
                  ref: "invoiceRef",
                  isAmend: true,
                  categoryAddition: true,
                  onReValidate: _ctx.revalidate,
                  onUploadFile: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleFileUpload($event))),
                  onUploadChequeFile: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleChequeFileUpload($event)))
                }, null, 8, ["onReValidate"])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules"]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_vc_button_navigator, { value: "groupInvoiceApproved" }),
                  _createVNode(_component_vc_button_save, {
                    loading: _ctx.clickLoading,
                    text: 'Save',
                    onSubmit: _cache[4] || (_cache[4] = ($event: any) => (_ctx.amendInvoice(_ctx.ruleFormRef)))
                  }, null, 8, ["loading"])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_vc_invoice_modal, {
      open: _ctx.openModal,
      onCancelModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeModal($event))),
      isAmend: true,
      isGroup: true,
      ref: "invoiceModalRef"
    }, null, 8, ["open"]),
    _createVNode(_component_vc_group_invoice_details),
    _createVNode(_component_vc_group_invoice_log)
  ], 64))
}